import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Avatar, AvatarFallback, AvatarImage } from "./ui/Avatar";
import ThemeSwitch from "./ui/ThemeSwitch";
import Footer from "./ui/Footer";
import { fetchBusinessName } from "../lib/api/apiServices";
import "../App.css";

// Types
interface Product {
  is_recurring: boolean;
  price_after_disount: number;
  payment_frequency_interval: string;
  payment_frequency_count: number;
  trial_period_days: number;
  discount: number;
  price: number;
  product_name: string;
  product_image: string;
  product_description: string;
}

interface CheckoutProductInfoProps {
  productData: Product & {
    currency: string;
    business_id: string;
  };
}

// Helper Components
const TrialPeriodInfo: React.FC<{ trialDays: number }> = ({ trialDays }) => {
  if (trialDays <= 0) return null;

  const startDate = new Date(Date.now() + trialDays * 24 * 60 * 60 * 1000);

  return (
    <span className="text-text-secondary  text-sm">
      (Starting from {startDate.toLocaleDateString()})
    </span>
  );
};

const ProductImage: React.FC<{
  name: string;
  image: string;
  quantity: number;
}> = ({ name, image, quantity }) => (
  <div className="aspect-square rounded-lg w-[120px] h-[120px] relative">
    <img alt={name} src={image} className="object-cover rounded-lg" />
  </div>
);

const RecurringPrice: React.FC<{ product: Product; currency: string }> = ({
  product,
  currency,
}) => {
  const { t } = useTranslation();
  const intervalKey = `product.interval.${product.payment_frequency_interval.toLowerCase()}`;

  return (
    <div className="flex flex-col text-nowrap items-start lg:items-end gap-1 w-fit">
      <div className="flex items-start lg:items-center flex-col lg:flex-row justify-end lg:gap-2">
        {product.discount > 0 && (
          <span className="line-through text-start text-text-secondary">
            {t("product.price", {
              price: (product.price / 100).toFixed(2),
              currency,
            })}
          </span>
        )}
        <span className="font-medium font-body text-text-primary text-base">
          {t("product.price", {
            price: (product.price_after_disount / 100).toFixed(2),
            currency,
          })}
          {t(intervalKey, { count: product.payment_frequency_count })}
        </span>
      </div>
      <TrialPeriodInfo trialDays={product.trial_period_days} />
    </div>
  );
};

const OneTimePrice: React.FC<{ product: Product; currency: string }> = ({
  product,
  currency,
}) => {
  const { t } = useTranslation();

  if (product.discount === 0) {
    return (
      <span className="font-medium font-body text-text-primary text-base">
        {t("product.price", {
          price: (product.price_after_disount / 100).toFixed(2),
          currency,
        })}
      </span>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row items-center gap-1">
      <span className="line-through text-text-secondary">
        {t("product.price", {
          price: (product.price / 100).toFixed(2),
          currency,
        })}
      </span>
      <span className="text-text-primary">
        {t("product.price", {
          price: (product.price_after_disount / 100).toFixed(2),
          currency,
        })}
      </span>
    </div>
  );
};

const ProductCard: React.FC<{
  product: Product;
  currency: string;
  quantity: number;
}> = ({ product, currency, quantity }) => (
  <div className="flex flex-col gap-3 items-start ">
    <div className="flex gap-4 lg:gap-6 w-full items-start">
      <ProductImage
        name={product.product_name}
        image={product.product_image}
        quantity={quantity}
      />
      <div className="flex lg:flex-row flex-col items-start justify-between w-full">
        <div className="flex flex-col w-full gap-2">
          <h2 className="font-display text-xl font-medium text-text-primary">
            {product.product_name}
          </h2>
          <span className="text-text-primary font-medium text-sm">
            Quantity: {quantity}
          </span>
          <p className="text-text-secondary hidden lg:block text-sm font-normal text-wrap">
            {product.product_description}
          </p>
        </div>
        {product.is_recurring ? (
          <RecurringPrice product={product} currency={currency} />
        ) : (
          <OneTimePrice product={product} currency={currency} />
        )}
      </div>
    </div>
    <p className="text-text-secondary lg:hidden text-sm font-normal text-wrap">
      {product.product_description}
    </p>
  </div>
);

const PriceSummary: React.FC<{
  subtotal: number;
  currency: string;
  quantity: number;
  product: Product & { currency: string; business_id: string };
}> = ({ subtotal, currency, product, quantity }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col border-t py-4 border-border-primary font-normal text-sm text-text-secondary pb-4 gap-4">
        <div className="flex items-center justify-between">
          <span>{t("orderSummary.subtotal")}</span>
          <span>
            {t("product.price", {
              price: ((product.price * quantity) / 100).toFixed(2),
              currency,
            })}
          </span>
        </div>
        {product.discount > 0 && (
          <div className="flex items-center justify-between">
            <span>Discount</span>
            <span>
              $
              {((product.price - product.price_after_disount) * quantity) / 100}{" "}
              ({product.discount}%)
            </span>
          </div>
        )}

        <div className="flex items-center justify-between">
          <span>{t("orderSummary.tax")}</span>
          <span>TBD</span>
        </div>
      </div>
      <div className="flex border-t py-4 border-border-primary text-base font-medium text-text-primary items-center justify-between">
        <span>{t("orderSummary.total")}</span>
        <span>
          {t("product.price", {
            price: (subtotal / 100).toFixed(2),
            currency,
          })}
        </span>
      </div>
    </>
  );
};

const CheckoutProductInfo: React.FC<CheckoutProductInfoProps> = ({
  productData,
}) => {
  const [searchParams] = useSearchParams();
  const [businessName, setBusinessName] = useState<{
    name: string;
    image: string;
  }>({ name: "", image: "" });

  const quantity = parseInt(searchParams.get("quantity") || "1", 10);
  const subtotal = productData.price_after_disount * quantity;

  useEffect(() => {
    const getBusinessName = async () => {
      try {
        const data = await fetchBusinessName(productData.business_id);
        setBusinessName(data);
      } catch (error) {
        console.error("Error loading product details:", error);
      }
    };
    getBusinessName();
  }, [productData.business_id]);

  if (!productData) {
    return <div>No product data available</div>;
  }

  const formattedBusinessName = businessName
    ? `${
        businessName.name.charAt(0).toUpperCase() + businessName.name.slice(1)
      } - Checkout`
    : "Dodopayments - Checkout";

  return (
    <section className="lg:h-screen flex flex-col lg:overflow-hidden">
      <Helmet>
        <title>{formattedBusinessName}</title>
      </Helmet>

      <header className="flex mx-4 lg:mx-8 pt-8 justify-between py-4 border-b border-border-primary">
        <div className="text-text-primary flex items-center gap-2 text-xl pt-2 capitalize font-display font-medium">
          <Avatar>
            <AvatarImage src={businessName.image} alt={businessName.name} />
            <AvatarFallback name={businessName.name} singleInitials={true} />
          </Avatar>
          <span>{businessName.name}</span>
        </div>
        <ThemeSwitch />
      </header>

      <div className="flex-grow p-4 lg:p-8 overflow-auto">
        <div className="flex flex-col gap-4 lg:gap-8 mb-8">
          <ProductCard
            product={productData}
            quantity={quantity}
            currency={productData.currency}
          />
        </div>

        <PriceSummary
          product={productData}
          subtotal={subtotal}
          quantity={quantity}
          currency={productData.currency}
        />
      </div>

      <div className="hidden pt-4 lg:block">
        <Footer />
      </div>
    </section>
  );
};

export default CheckoutProductInfo;
