import { MODE } from "../lib/constants/api";

const Banner = () => {
  if (MODE === false) return null;
  return (
    <div className="w-[100vw]  absolute  z-20 top-0 flex flex-col items-center">
      <div className="h-1 w-[100vw] hidden lg:block shadow-md bg-[#22272F] dark:bg-background-secondary "></div>
      <div className="lg:rounded-b-md shadow-md font-medium text-sm font-body h-fit w-full lg:w-fit bg-[#22272F] dark:bg-background-secondary text-center  text-white px-3 py-2 pt-1">
        You are in Test Mode.
        <a href="/" className="ml-2 underline">
          Learn More
        </a>
      </div>
    </div>
  );
};

export default Banner;
