// Footer.js
import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useTheme } from "../../lib/ThemeContext";

const Footer = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <footer className="flex w-full px-8  flex-col items-center gap-2 py-4">
      <div className="flex w-full items-center justify-center lg:justify-between ">
        {theme !== "dark" ? (
          <img alt="dodopayments logo" src="/dodo_logo.svg" />
        ) : (
          <img alt="dodopayments logo" src="/dodo_logo_dark.svg" />
        )}
        <div className="lg:flex hidden items-center gap-2 text-text-primary font-normal text-[13px]">
          <a
            href="https://dodopayments.com/terms-of-use"
            className="hover:no-underline underline"
          >
            {t("footer.termsAndConditions")}
          </a>
          <a
            href="https://dodopayments.com/privacy"
            className="hover:no-underline underline"
          >
            {t("footer.privacyPolicy")}
          </a>
        </div>
      </div>
      <p className="text-text-secondary w-full font-normal text-center lg:text-start text-[13px] leading-5">
        <Trans i18nKey="footer.description">
          This order process is conducted by our online reseller & Merchant of
          Record, <strong>Dodo Payments</strong>, who also handles order-related
          inquiries and returns.
        </Trans>
      </p>
      <div className="flex lg:hidden items-center gap-2 text-text-primary font-normal text-[13px]">
          <a
            href="https://dodopayments.com/terms-of-use"
            className="hover:no-underline underline"
          >
            {t("footer.termsAndConditions")}
          </a>
          <a
            href="https://dodopayments.com/privacy-policy"
            className="hover:no-underline underline"
          >
            {t("footer.privacyPolicy")}
          </a>
        </div>
    </footer>
  );
};

export default Footer;
