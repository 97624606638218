import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ThemeSwitch from "./ui/ThemeSwitch";
import {Helmet} from "react-helmet";
import "../App.css";
import Footer from "./ui/Footer";
import { Product } from "../types/types";
import { fetchBusinessName } from "../lib/api/apiServices";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/Avatar";

interface ProductCardProps {
  product: Product;
  currency: string;
  isRecurring: boolean;
}

interface PriceSummaryItemProps {
  label: string;
  value: string;
}

interface ProductInfoProps {
  products: Product[];
  currency: string;
  business_id: string;
  amount: number;
  isRecurring: boolean;
}

interface OrderSummary {
  subtotal: number;
  tax: number;
  total: number;
}

const ProductCard = ({ product, currency, isRecurring }: ProductCardProps) => {
  const { t } = useTranslation();

  const RecurringPrice = () => (
    <div className="flex flex-col text-nowrap items-start lg:items-end gap-1 w-fit">
      <div className="flex items-start lg:items-center flex-col lg:flex-row justify-end lg:gap-2">
        <span className="font-medium font-body text-text-primary text-base">
          {t("product.price", {
            price: (product.price_after_discount / 100).toFixed(2),
            currency,
          })}
          {" "}
          {t(
            `product.interval.${product.payment_frequency_interval?.toLowerCase()}`,
            { count: product.payment_frequency_count }
          )}
        </span>
      </div>
      {product.trial_period_days && product.trial_period_days > 0 && (
        <span className="text-text-secondary text-sm">
          (Starting from {" "}
          {new Date(
            Date.now() + product.trial_period_days * 24 * 60 * 60 * 1000
          ).toLocaleDateString()}
          )
        </span>
      )}
    </div>
  );

  const OneTimePrice = () => (
    <div className="flex flex-col lg:flex-row items-center gap-1">
      {product.discount !== 0 ? (
        <>
          <span className="line-through text-text-secondary">
            {t("product.price", {
              price: (product.price / 100).toFixed(2),
              currency,
            })}
          </span>
          <span className="text-text-primary">
            {t("product.price", {
              price: (product.price_after_discount / 100).toFixed(2),
              currency,
            })}
          </span>
        </>
      ) : (
        <span className="font-medium font-body text-text-primary text-base">
          {t("product.price", {
            price: (product.price_after_discount / 100).toFixed(2),
            currency,
          })}
        </span>
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-3 items-start">
      <div className="flex gap-4 lg:gap-6 w-full items-start">
        <div className="aspect-square rounded-lg w-[120px] h-[120px] relative">
          <img
            alt={product.name}
            src={
              "https://dodo-public-sandbox.s3.ap-south-1.amazonaws.com/" +
              product.image
            }
            className="object-cover rounded-lg"
          />
        </div>
        <div className="flex lg:flex-row flex-col items-start justify-between w-full">
          <div className="flex flex-col w-full gap-2">
            <h2 className="font-display text-xl font-medium text-text-primary">
              {product.name}
            </h2>
            <span className="text-text-primary font-medium text-sm">
              Quantity: {product.quantity}
            </span>
            <p className="text-text-secondary hidden lg:block text-sm font-normal text-wrap">
              {product.description}
            </p>
          </div>
          {isRecurring ? <RecurringPrice /> : <OneTimePrice />}
        </div>
      </div>
      <p className="text-text-secondary lg:hidden text-sm font-normal text-wrap">
        {product.description}
      </p>
    </div>
  );
};



const PriceSummaryItem: React.FC<PriceSummaryItemProps> = ({
  label,
  value,
}) => (
  <div className="flex items-center justify-between">
    <span>{label}</span>
    <span>{value}</span>
  </div>
);

const ProductInfo: React.FC<ProductInfoProps> = ({
  products = [],
  currency = "USD",
  amount = 0,
  business_id,
  isRecurring = false,
}) => {
  const { t } = useTranslation();
  const [businessName, setBusinessName] = useState<{
    name: string;
    image: string;
  }>({ name: "", image: "" });

  const orderSummary = useMemo<OrderSummary>(() => {
    const subtotal = amount;
    const tax = 0;
    const total = subtotal;
    return { subtotal, tax, total };
  }, [amount]);

  useEffect(() => {
    const GetBusinessName = async () => {
      try {
        const data = await fetchBusinessName(business_id || "");
        setBusinessName(data);
      } catch (error) {
        console.error("Error loading product details:", error);
      } finally {
      }
    };
    GetBusinessName();
  }, [business_id]);

  if (!products.length) {
    return <div>No products available</div>;
  }

  return (
    <section className="lg:h-screen flex flex-col lg:overflow-hidden">
      <Helmet>
        <title>{`${businessName.name ? `${businessName.name.charAt(0).toUpperCase() + businessName.name.slice(1)} - Checkout` : "Dodopayments - Checkout"}`}</title>
    </Helmet>
      <header className="flex mx-4 lg:mx-8  pt-8 justify-between py-4 border-b border-border-primary">
        <div className="text-text-primary flex items-center gap-2 text-2xl pt-2 capitalize font-display font-medium">
        <Avatar>
            <AvatarImage src={businessName.image} alt={businessName.name} />
            <AvatarFallback name={businessName.name} singleInitials={true} />
          </Avatar>
          <span>{businessName.name}</span>
        </div>
        <ThemeSwitch />
      </header>

      <div className="flex-grow p-4 lg:p-8 overflow-auto">
        <div className="">
          <div className="flex flex-col gap-8 mb-8">
            {products.map((product) => (
              <ProductCard
                key={product.product_id}
                product={product}
                currency={currency}
                isRecurring={isRecurring}
              />
            ))}
          </div>

          <div className="flex flex-col border-t py-4 border-border-primary font-normal text-sm text-text-secondary pb-4 gap-4">
            <PriceSummaryItem
              label={t("orderSummary.subtotal")}
              value={t("product.price", {
                price: (orderSummary.subtotal / 100).toFixed(2),
                currency,
              })}
            />
            <PriceSummaryItem
              label={t("orderSummary.tax")}
              value={t("product.price", {
                price: (orderSummary.tax / 100).toFixed(2),
                currency,
              })}
            />
          </div>

          <div className="flex border-t py-4 border-border-primary text-base font-medium text-text-primary items-center justify-between">
            <span>{t("orderSummary.total")}</span>
            <span>
              {t("product.price", {
                price: (orderSummary.total / 100).toFixed(2),
                currency,
              })}
            </span>
          </div>
        </div>
      </div>

      <div className="hidden pt-4 lg:block">
        <Footer />
      </div>
    </section>
  );
};

export default ProductInfo;
