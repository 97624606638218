import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./i18n";

import { ThemeProvider } from "./lib/ThemeContext";
import LinkExpiredPage from "./pages/StatusPages/linkExpired";
import CheckoutPage from "./pages/Checkout";
import BuyPage from "./pages/Buy";
import UnifiedStatusPage from "./pages/StatusPages/Status";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/:id" element={<CheckoutPage/>} />
          <Route path="/buy/:product_id" element={<BuyPage />} />
          <Route path="/:id/status/:status" element={<UnifiedStatusPage />} />
          <Route path="/status/link-expired" element={<LinkExpiredPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
