import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useSearchParams } from "react-router-dom";
import { PaymentDetails } from "../types/types";
import { COUNTRIES } from "./ui/CountrySelector/countries";
import CountrySelector from "./ui/CountrySelector/selector";
import { SelectMenuOption } from "./ui/CountrySelector/types";
import { toast } from "react-hot-toast";
import "../styles/CustomerForm.css";

const formSchema = z.object({
  firstName: z.string().min(2, "First name must be at least 2 characters"),
  lastName: z.string().min(2, "Last name must be at least 2 characters"),
  email: z.string().email("Invalid email address"),
  country: z.string().min(2, "Country must be at least 2 characters"),
  addressLine: z.string().min(5, "Address must be at least 5 characters"),
  city: z.string().min(2, "City must be at least 2 characters"),
  zipCode: z.string().min(5, "Zip code must be at least 5 characters"),
  phoneNumber: z.string().optional(),
});

type FormData = z.infer<typeof formSchema>;

interface ProductData {
  is_recurring: boolean;
  product_id: string;
}

interface CustomerFormProps {
  productData: ProductData;
  onSubmit: (paymentDetails: PaymentDetails) => void;
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  productData,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      country: "US",
    },
  });

  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>("US");
  const [phoneInputMeta, setPhoneInputMeta] = useState<{
    country: any;
    inputValue: string;
  } | null>(null);

  const handleFormSubmit = (data: FormData) => {
    if (data.phoneNumber) {
      const phoneValue = phoneInputMeta?.inputValue || "";
      const hasOnlyCountryCode =
        phoneValue.trim() === `+${phoneInputMeta?.country.dialCode}`;

      if (hasOnlyCountryCode) {
        delete data.phoneNumber;
      } else if (phoneValue.length < phoneInputMeta?.country.format.length) {
        toast.error("Please enter a complete phone number");
        return;
      }
    }

    const billingInfo = {
      city: data.city,
      country: data.country,
      state: "",
      street: data.addressLine,
      zipcode: parseInt(data.zipCode, 10),
    };

    const customerInfo = {
      email: data.email,
      name: `${data.firstName} ${data.lastName}`,
      phone_number: data.phoneNumber || null,
    };

    let paymentDetails: PaymentDetails;
    const quantity = parseInt(searchParams.get("quantity") || "1", 10);
    const redirect_url = searchParams.get("redirect_url") || null;

    try {
      if (productData.is_recurring) {
        paymentDetails = {
          CreateSubscriptionRequest: {
            billing: billingInfo,
            customer: customerInfo,
            payment_link: true,
            product_id: productData.product_id,
            quantity: quantity,
            return_url: redirect_url,
          },
        };
      } else {
        paymentDetails = {
          CreateOneTimePaymentRequest: {
            billing: billingInfo,
            customer: customerInfo,
            payment_link: true,
            product_cart: [
              {
                product_id: productData.product_id,
                quantity: quantity,
              },
            ],
            return_url: redirect_url,
          },
        };
      }

      onSubmit(paymentDetails);
    } catch (error) {
      toast.error("Failed to process payment details. Please try again.");
    }
  };
  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="lg:p-8 p-4 lg:px-[72px] h-fit font-body w-full"
    >
      <h2 className="text-base font-medium mb-3 font-display text-text-primary">
        Enter your information
      </h2>

      <div className="flex flex-col lg:flex-row gap-4 mb-4">
        <div className="flex-1">
          <label htmlFor="firstName" className="form-label">
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            {...register("firstName")}
            type="text"
            id="firstName"
            placeholder="eg: John"
            className="form-input"
          />
          {errors.firstName && (
            <p className="text-red-600 text-sm mt-1">
              {errors.firstName.message}
            </p>
          )}
        </div>

        <div className="flex-1">
          <label htmlFor="lastName" className="form-label">
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            {...register("lastName")}
            type="text"
            id="lastName"
            placeholder="eg: Doe"
            className="form-input"
          />
          {errors.lastName && (
            <p className="text-red-600 text-sm mt-1">
              {errors.lastName.message}
            </p>
          )}
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="email" className="form-label">
          Email <span className="text-red-500">*</span>
        </label>
        <input
          {...register("email")}
          type="email"
          id="email"
          placeholder="eg: johndoe@example.com"
          className="form-input"
        />
        {errors.email && (
          <p className="text-red-600 text-sm mt-1">{errors.email.message}</p>
        )}
      </div>

      <h3 className="text-base font-medium mb-1 font-display text-text-primary">
        Billing Address <span className="text-red-500">*</span>
      </h3>

      <div className="mb-4">
        <label htmlFor="country" className="form-label">
          Country <span className="text-red-500">*</span>
        </label>
        <Controller
          name="country"
          control={control}
          defaultValue="US"
          render={({ field: { onChange } }) => (
            <CountrySelector
              id="country-selector"
              open={isOpen}
              onToggle={() => setIsOpen(!isOpen)}
              onChange={(val) => {
                setSelectedCountry(val);
                onChange(val); // Use the country code value directly
              }}
              selectedValue={
                COUNTRIES.find(
                  (option) => option.value === selectedCountry
                ) as SelectMenuOption
              }
            />
          )}
        />
        {errors.country && (
          <p className="text-red-600 text-sm mt-1">{errors.country.message}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="addressLine" className="form-label">
          Address line
        </label>
        <input
          {...register("addressLine")}
          type="text"
          id="addressLine"
          placeholder="Eg: 364 Kent St"
          className="form-input"
        />
        {errors.addressLine && (
          <p className="text-red-600 text-sm mt-1">
            {errors.addressLine.message}
          </p>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-4 mb-4">
        <div className="flex-1">
          <label htmlFor="city" className="form-label">
            City
          </label>
          <input
            {...register("city")}
            type="text"
            id="city"
            placeholder="Sydney"
            className="form-input"
          />
          {errors.city && (
            <p className="text-red-600 text-sm mt-1">{errors.city.message}</p>
          )}
        </div>

        <div className="flex-1">
          <label htmlFor="zipCode" className="form-label">
            Zipcode
          </label>
          <input
            {...register("zipCode")}
            type="text"
            id="zipCode"
            placeholder="Eg: 2035"
            className="form-input"
          />
          {errors.zipCode && (
            <p className="text-red-600 text-sm mt-1">
              {errors.zipCode.message}
            </p>
          )}
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="phoneNumber" className="form-label">
          Phone Number (optional)
        </label>
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              value={value}
              onChange={(phone, meta) => {
                onChange(phone);
                setPhoneInputMeta(meta);
              }}
              defaultCountry="us"
              countrySelectorStyleProps={{
                flagClassName: "px-1",
                buttonClassName:
                  "border border-[#d0d5dd] px-2 bg-[#ffffff] dark:bg-[#0d0d0d] dark:border-[#323232] hover:bg-bg-secondary dark:hover:bg-[#1b1b1b]",
                dropdownStyleProps: {
                  listItemClassName:
                    "px-2 bg-[#ffffff] dark:bg-[#0d0d0d] hover:bg-bg-secondary dark:hover:bg-[#1b1b1b]",
                  className:
                    "rounded-lg bg-[#ffffff] border dark:border-[#323232] border-[#d0d5dd] dark:bg-[#0d0d0d] text-text-primary dark:text-[#ffffff]",
                },
              }}
              inputProps={{
                className:
                  "w-full font-body text-sm font-normal border border-[#d0d5dd] bg-[#ffffff] dark:bg-[#0d0d0d] dark:border-[#323232] px-2 rounded-r-lg text-text-primary dark:text-white " +
                  "focus:outline-none focus:ring-2 focus:ring-[#a6e500] dark:focus:ring-[#a6e500] focus:border-transparent",
              }}
            />
          )}
        />
        {errors.phoneNumber && (
          <p className="text-red-600 text-sm mt-1">
            {errors.phoneNumber.message}
          </p>
        )}
      </div>

      <button
        type="submit"
        className="w-full py-2 px-4 font-normal hover:text-black border border-transparent hover:border-border-secondary mt-6 bg-button-primary-bg dark:text-black font-display text-white rounded-lg shadow-sm hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        Continue to Payment
      </button>
    </form>
  );
};

export default CustomerForm;
