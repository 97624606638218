import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { fetchProductDetailsCheckout, createPaymentLink } from "../lib/api/apiServices";
import Footer from "../components/ui/Footer";
import CustomerForm from "../components/CustomerForm";
import LoadingOverlay from "../components/ui/Loading";
import CheckoutProductInfo from "../components/CheckoutProductInfo";
import { PaymentDetails } from "../types/types";
import Banner from "../components/banner";
import toast, { Toaster } from "react-hot-toast";

interface ProductData {
  business_id: string;
  is_recurring: boolean;
  price_after_disount: number;
  payment_frequency_interval: string;
  payment_frequency_count: number;
  trial_period_days: number;
  discount: number;
  price: number;
  product_name: string;
  product_image: string;
  product_description: string;
  currency: string;
  product_id: string;
}

function useProductData(product_id: string | undefined) {
  const [loading, setLoading] = useState<boolean>(false);
  const [productData, setProductData] = useState<ProductData | null>(null);

  useEffect(() => {
    if (!product_id) return;
    setLoading(true);

    const loadProductDetails = async () => {
      try {
        const data = await fetchProductDetailsCheckout(product_id);
        setProductData(data);
      } catch (error) {
        console.error("Error loading product details:", error);
      } finally {
        setLoading(false);
      }
    };

    loadProductDetails();
  }, [product_id]);

  return { loading, productData };
}

function BuyPage() {
  const { product_id } = useParams<Record<string, string | undefined>>();
  const { loading, productData } = useProductData(product_id);

  const handleError = useCallback((error: unknown) => {
    const message =
      error instanceof Error
        ? error.message.split("\n").map((line, index) => <div key={index} className="mt-1">{line}</div>)
        : "Failed to process payment. Please try again.";
    
    toast.error(
      <div className="flex flex-col gap-1">{message}</div>,
      { duration: 5000, style: { maxWidth: "500px", padding: "16px" } }
    );
  }, []);

  const handleSubmit = useCallback(
    async (paymentDetails: PaymentDetails) => {
      if (!productData?.business_id) {
        handleError(new Error("Business ID is required"));
        return;
      }

      try {
        const paymentLinkData = { business_id: productData.business_id, payment_details: paymentDetails };
        const paymentLink = await createPaymentLink(paymentLinkData);
        window.location.href = `/${paymentLink}`;
      } catch (error) {
        console.error("Error creating payment link:", error);
        handleError(error);
      }
    },
    [productData, handleError]
  );

  if (loading) return <LoadingOverlay />;

  return (
    <div className="app flex font-body lg:flex-row flex-col w-[100vw] h-screen">
      <Toaster position="top-right" />
      <Banner />
      <meta name="title" content="Dodo Checkout" />
      <section className="lg:w-1/2 lg:overflow-auto scrollable-element bg-background-primary lg:bg-background-secondary border-r border-border-secondary flex justify-between flex-col">
        {productData && <CheckoutProductInfo productData={productData} />}
      </section>
      <section className="flex lg:flex-row flex-col py-10 lg:min-h-screen lg:overflow-auto lg:w-1/2 justify-center items-center bg-background-primary">
        <div className="flex flex-col h-fit w-full lg:h-full items-center justify-center">
          {productData && <CustomerForm productData={productData} onSubmit={handleSubmit} />}
          <div className="block lg:hidden">
            <Footer />
          </div>
        </div>
      </section>
        
    </div>
  );
}

export default BuyPage;
