import { Sun, Moon } from '@phosphor-icons/react';
import { useTheme } from '../../lib/ThemeContext';

function ThemeSwitch() {
  const { enabled, handleThemeChange } = useTheme();

  return (
    <div
      onClick={() => handleThemeChange(!enabled)}
      aria-label="Toggle Dark Mode"
      className="w-fit p-3 cursor-pointer"
    >
      {enabled ? (
        <Moon className="w-5 h-5" />
      ) : (
        <Sun className="w-5 h-5 text-white" />
      )}
    </div>
  );
}

export default ThemeSwitch;
